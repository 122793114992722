.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.grid-container {
  display: grid;
  gap: 20px;
  padding: 20px;
}

@media (min-width: 1200px) {
  /* Large screen layout */
  .grid-container {
    grid-template-areas:
      ". header ."
      "certifications experience skills"
      "courses experience skills";
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: auto auto auto auto;
  }

}

@media (min-width: 768px) and (max-width: 1199px) {
  /* Medium screen layout */
  .grid-container {
    grid-template-areas:
      "header header"
      "certifications experience"
      "courses experience"
      "skills experience";
    grid-template-columns: 1fr 2fr;
  }
}

@media (max-width: 767px) {
  /* Small screen/mobile layout */
  .grid-container {
    grid-template-areas:
      "header"
      "experience"
      "certifications"
      "courses"
      "skills";
    grid-template-columns: 1fr;
  }
}

.header {
  grid-area: header;
}

.experience {
  grid-area: experience;
}

.certifications {
  grid-area: certifications;
}

.courses {
  grid-area: courses;
}

.skills {
  grid-area: skills;
}
