* {
    /* transition: all 0.6s ease-in-out; */
    cursor: url('/src/assets/cursor.png'), auto;
}

body {
    cursor: url('/src/assets/cursor.png'), auto;
}

*:hover {
    cursor: url('/src/assets/hover-cursor.png'), auto;
}

.custom-badge {
    color: rgb(255, 255, 255);
    background-color: inherit;
  }

.bg-primary {

}